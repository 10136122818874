
function Concepto({ onConceptoChange }) {
    return (
      <div className="otra">
      <div className='flexi'>
      <div className='train1'>
        
             <div className='flexi'>
             
             <select className="texteo" onChange={onConceptoChange}>
  <option value="">Concepto a enseñar</option>
  <option value="Honestidad">Honestidad</option>
  <option value="Humildad">Humildad</option>
  <option value="Amabilidad">Amabilidad</option>
  <option value="Respeto">Respeto</option>
  <option value="Responsabilidad">Responsabilidad</option>
  <option value="Generosidad">Generosidad</option>
  <option value="Tolerancia">Tolerancia</option>
  <option value="Integridad">Integridad</option>
  <option value="Perseverancia">Perseverancia</option>
  <option value="Empatía">Empatía</option>
  <option value="Solidaridad">Solidaridad</option>
  <option value="Justicia">Justicia</option>
  <option value="Autocontrol">Autocontrol</option>
  <option value="Agradecimiento">Agradecimiento</option>
  <option value="Valentía">Valentía</option>
  <option value="Paciencia">Paciencia</option>
  <option value="Resiliencia">Resiliencia</option>
  <option value="Sinceridad">Sinceridad</option>
</select>
      
             </div>
             </div>
             </div>
      </div>
    );
  }
  export default Concepto;
  