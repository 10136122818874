import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext.js';
import { Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';



import logoImg from '../assets/logo1.png';

export default function MisCuentosAmpliados() {



  const { currentUser } = useAuth();
  const clienteUID = currentUser.uid;
  const clienteRef = db.collection('Clientes').doc(clienteUID);
  const [respuestas, setRespuestas] = useState([]);

 


  const copiarTextoAlPortapapeles = async (texto) => {
    try {
      await navigator.clipboard.writeText(texto);
      alert('Texto copiado al portapapeles.');
    } catch (error) {
      console.error('Error al copiar al portapapeles:', error);
    }
  };

  
  useEffect(() => {
    const obtenerRespuestas = async () => {
      try {
        const respuestasRef = clienteRef.collection('RespuestaAmplia');
        const respuestasSnapshot = await respuestasRef.get();

        if (!respuestasSnapshot.empty) {
          const respuestasData = respuestasSnapshot.docs.map((doc) => doc.data().Sumatorio);
          setRespuestas(respuestasData);
        } else {
          console.log('El cliente no tiene respuestas almacenadas en la subcolección.');
        }
      } catch (error) {
        console.error('Error al obtener respuestas:', error);
      }
    };

    obtenerRespuestas();
  }, [clienteRef]);
      


  return (
    <div className="conRes">
       <div className='d-flex align-items-center justify-content-center mt-2'>
       <Link to="/"><img src={logoImg} alt='' width="150" height="150" className=''/></Link>
        </div>
      <h2 className="text-center">Mis Cuentos Ampliados</h2>
      <div className="">
        {respuestas.length > 0 ? (
          respuestas.map((respuesta, index) => (
            <div key={index} className="card m-4 bor esca">
              <div className="card-body">
                <p className="card-text text-center">{respuesta}</p>
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    className="btn btn-warning"
                    onClick={() => copiarTextoAlPortapapeles(respuesta)}
                  >
                    Copiar
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center mt-4">No hay respuestas disponibles.</div>
        )}
      </div>
      <div className="canto  d-flex comof justify-content-center align-items-center">
<div className="mb-4 w-75">
      <Link to="/MisCuentos" className="btn btn-warning w-100 mt-3 mb-2">
        MIS CUENTOS
      </Link>
      <Link to="/" className="btn btn-light w-100 mt-1">
        INICIO
      </Link>
      </div>
      </div>
     

    </div>
  );
}