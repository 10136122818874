import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from "react-bootstrap";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';

import PublicRoute from './components/routes/PublicRoute';
import PrivateRoute from './components/routes/PrivateRoute';
import PublicRouteCheck from './components/routes/PublicRouteCheck';
import PublicRouteCheck2 from './components/routes/PublicRouteCheck2';
import PublicRouteCheck3 from './components/routes/PublicRouteCheck3';

import Opencito from './components/open/Opencito';

import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Signup from './components/Signup';
import Despues from './components/carg/Despues';
import UpdateProfile from './components/UpdateProfile';
import ForgotPassword from './components/ForgotPassword';
import MisCuentos from './components/MisCuentos';
import Precios2 from './components/Precios2';
import SignCheckLog from './components/carg/SignCheckLog';
import SignCheckLog2 from './components/carg/SignCheckLog2';
import SignCheckLog3 from './components/carg/SignCheckLog3';
import MisCuentosAmpliados from './components/MisCuentosAmpliados';
import MisAudios from './components/MisAudios';
import Exito from './components/carg/Exito';
import SignBuy from './components/SignBuy';
import SignBuy2 from './components/SignBuy2';
import SignBuy3 from './components/SignBuy3';
import SignCheck from './components/carg/SignCheck';
import SignCheck2 from './components/carg/SignCheck2';
import SignCheck3 from './components/carg/SignCheck3';




function App() {
  return (

    <Container 
    className='d-flex align-items-center justify-content-center'
    style={{ minHeight: "100vh" }}
    >
     <div className="w-100" style={{ maxWidth: '500px' }}>


    <Router>
    <AuthProvider>
      <Routes>

     <Route exact path='/signbuy' Component={PublicRouteCheck}>
               <Route exact path='/signbuy' Component={SignBuy}/>
            </Route>
              <Route exact path='/signbuy2' Component={PublicRouteCheck2}>
               <Route exact path='/signbuy2' Component={SignBuy2}/>
            </Route>
            <Route exact path='/signbuy3' Component={PublicRouteCheck3}>
               <Route exact path='/signbuy3' Component={SignBuy3}/>
            </Route> 

<Route exact path='/C0D1E2F3gH4I5J6K7lM8N9O0P1Q2rS3' Component={PrivateRoute}>
               <Route exact path='/C0D1E2F3gH4I5J6K7lM8N9O0P1Q2rS3' Component={SignCheck}/>
            </Route>

            <Route exact path='/X2Y3zA4B5C6D7E8F9G0H1I2J3K4lM5' Component={PrivateRoute}>
               <Route exact path='/X2Y3zA4B5C6D7E8F9G0H1I2J3K4lM5' Component={SignCheck2}/>
            </Route>

            <Route exact path='/I8J9K0lM1N2O3P4Q5R6S7T8U9V0W1' Component={PrivateRoute}>
               <Route exact path='/I8J9K0lM1N2O3P4Q5R6S7T8U9V0W1' Component={SignCheck3}/>
            </Route>

            <Route exact path='/sessiontop' Component={PrivateRoute}>
               <Route exact path='/sessiontop' Component={SignCheckLog}/>
            </Route>
            <Route exact path='/sessiontop2' Component={PrivateRoute}>
               <Route exact path='/sessiontop2' Component={SignCheckLog2}/>
            </Route>
            <Route exact path='/sessiontop3' Component={PrivateRoute}>
               <Route exact path='/sessiontop3' Component={SignCheckLog3}/>
            </Route>

            

          
      <Route exact path='/signup' Component={PublicRoute}>
               <Route exact path='/signup' Component={Signup}/>
            </Route>

            <Route exact path='/login' Component={PublicRoute}>
               <Route exact path='/login' Component={Login}/>
            </Route>

            <Route exact path='/forgot-password' Component={PublicRoute}>
               <Route exact path='/forgot-password' Component={ForgotPassword}/>
            </Route>



            <Route exact path='/F3gH4I5J6K7lM8N9O0P1Q2rS3T4U5' Component={PrivateRoute}>
               <Route exact path='/F3gH4I5J6K7lM8N9O0P1Q2rS3T4U5' Component={Despues}/>
              
            </Route>

            <Route exact path='/' Component={PrivateRoute}>
               <Route exact path='/' Component={Dashboard}/>
            </Route>

            <Route exact path='/opencito' Component={PrivateRoute}>
               <Route exact path='/opencito' Component={Opencito}/>
            </Route>


            <Route exact path='/miscuentos' Component={PrivateRoute}>
               <Route exact path='/miscuentos' Component={MisCuentos}/>
            </Route>
            <Route exact path='/miscuentosampliados' Component={PrivateRoute}>
               <Route exact path='/miscuentosampliados' Component={MisCuentosAmpliados}/>
            </Route>
            <Route exact path='/misaudios' Component={PrivateRoute}>
               <Route exact path='/misaudios' Component={MisAudios}/>
            </Route>

            <Route exact path='/precios' Component={PrivateRoute}>
               <Route exact path='/precios' Component={Precios2}/>
            </Route>
            <Route exact path='/Y3zA4B5C6D7E8F9G0H1I2J3K4lM5N6' Component={PrivateRoute}>
               <Route exact path='/Y3zA4B5C6D7E8F9G0H1I2J3K4lM5N6' Component={Exito}/> 
            </Route>

        

            

            <Route exact path='/update-profile' Component={PrivateRoute}>
               <Route exact path='/update-profile' Component={UpdateProfile}/>
            </Route>



      </Routes>
        </AuthProvider>
      </Router>


      </div>
      </Container>
  );
}

export default App;
