import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import { getAnalytics, logEvent } from "firebase/analytics";
import { db, analytics } from '../firebase';
import { Link } from 'react-router-dom';

import logoImg from '../assets/logo1.png';


const MisAudios = () => {
  const { currentUser } = useAuth();
  const [audioData, setAudioData] = useState([]);


  const currentPath = window.location.pathname;
  const analytics = getAnalytics();

 
  const clienteUID = currentUser.uid
  const clienteRef = db.collection('customers').doc(clienteUID)
  const clienteRef2 = db.collection('Clientes').doc(clienteUID)


  useEffect(() => {
    if (currentUser) {
      const storageRefPath = `audio/${currentUser.uid}`;
      const storage = getStorage();
      const storageRef = ref(storage, storageRefPath);

    


        clienteRef2.get().then((doc) => {
          if (doc.exists) {
            const nombre = doc.data().nombre;
            const apellidos = doc.data().apellidos;
            const fexa =  new Date();
            const lola = `Nombre: ${nombre} ${apellidos} | Página: ${currentPath} | Fecha: ${fexa} | UserUID: ${clienteUID}`;
            const clien = `Nombre: ${nombre} ${apellidos} | UserUID: ${clienteUID}`
            logEvent(analytics, 'page_view', {
              COMPLETO: lola, 
              CLIENTE: clien,
            });
            console.log('enteoriasehebviologeevent')
          } else {
            console.log('El documento del cliente no existe.');
          }
        });
       

      listAll(storageRef)
        .then(async (res) => {
          const audioPromises = res.items.map(async (item) => {
            const audioUrl = await getDownloadURL(item);
            const audioRef = db.collection(`Clientes/${currentUser.uid}/AudiosUser`).where('AudioUrl', '==', audioUrl);
            const audioQuery = await audioRef.get();
            if (!audioQuery.empty) {
              const audioDoc = audioQuery.docs[0];
              const audioData = audioDoc.data();
              return { audioUrl, cuentoTexto: audioData.CuentoParaEscuchar };
            } else {
              return { audioUrl, cuentoTexto: 'Texto no encontrado' };
            }
          });

          const audioData = await Promise.all(audioPromises);
          setAudioData(audioData);
        })
        .catch((error) => {
          console.error('Error al obtener URLs de descarga:', error);
        });
    }
  }, [currentUser]);


  const copiarTextoAlPortapapeles = async (texto) => {
    try {
      await navigator.clipboard.writeText(texto);
      alert('Texto copiado al portapapeles.');
    } catch (error) {
      console.error('Error al copiar al portapapeles:', error);
    }
  };

  return (
    <div>
       <div className='d-flex align-items-center justify-content-center mt-2'>
       <Link to="/"><img src={logoImg} alt='' width="150" height="150" className=''/></Link>
        </div>
      <h1 className='text-center'>Audio-Cuentos</h1>
  
      {audioData.length > 0 ? (
        audioData.map((audio, index) => (
          <div className="card d-flex justify-content-center align-items-center m-3 bg-dark white p-4 bor esca" key={index}>
            <p className='text-center'>{audio.cuentoTexto}</p>
            <div className="d-flex align-items-center justify-content-center mb-3">
                  <button
                    className="btn btn-warning"
                    onClick={() => copiarTextoAlPortapapeles(audio.cuentoTexto)}
                  >
                    Copiar
                  </button>
                </div>
            <div className="d-flex align-items-center justify-content-center">
              <audio controls className='audiete'>
                <source src={audio.audioUrl} type="audio/mpeg" />
                Tu navegador no soporta el elemento de audio.
              </audio>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center mt-4">No hay datos disponibles.</div>
      )}

<div className="canto  d-flex comof justify-content-center align-items-center">
<div className="mb-4 w-75">
        <Link to="/MisCuentos" className="btn btn-warning w-100 mt-3 mb-2">
        MIS CUENTOS
      </Link>
      
       <Link to="/" className="btn btn-light w-100 mt-1">
        INICIO
      </Link>
      </div>
      </div>
       
    </div>
  );

  
};

export default MisAudios;
