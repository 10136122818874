import React from 'react'
// import { Document } from 'react-pdf';
import { Document, Page, Text, View, Image, Font, Link } from '@react-pdf/renderer'

import dina from '../assets/dinA4.png';
import logoImg from '../assets/logo1.png';


export default function DocuPDF({ RespuestaX }) {

    // evito useefect y usestate para respuesta quitar coments

    Font.register({
        family: 'Roboto',
        fonts: [
          { src: 'https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxP.ttf', fontWeight: 'normal' },
          { src: 'https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmEU9fBBc4.woff2', fontWeight: 'bold' },
          // Puedes agregar más variantes según sea necesario
        ],
      });
      

  return (

    <Document>
        <Page 
        size="A4"
        style={{
            backgroundColor: "pink",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: '20px',
            // backgroundImage: `url(${boca})`, 
            // backgroundSize: "cover",
            }}
            // backgroundImage: boca, // Establece tu imagen como fondo
            // backgroundImage: `url(${tuImagen})`, 
            // backgroundSize: "cover",}}
            >
    <View
       style={{
    backgroundColor: "pink",
    // backgroundImage: `url(${boca})`, 
    // backgroundSize: "cover",
    }}
    >
{/* <Text>Hola como estás prueba</Text> */}
{/* <Image
            src={logoImg}
            style={{
                width: '130px',
                // height: '150px',
                //  backgroundSize: "cover",
        // opacity: 0.7 
    }}
          /> */}
<Image
            src={dina}
            style={{ backgroundSize: "cover",
        opacity: 0.5,
        // padding: '10px'
    // marginBottom: '10px'
 }}
          />
 


<Text
style={{ 
    position: "absolute",
    top: '14.5px',
    textAlign: "center",
    justifyContent: 'center',
    // top: '5px',
    fontFamily:'Roboto',
    fontSize: '28px',
    // fontWeight: 'bold',
    marginHorizontal: 'auto',
    // paddingLeft: '50px',
    // maxWidth: '370px',
    display: "flex", 
    alignSelf: "center",
    textDecoration: 'none',

}}
>
    <Link src="https://cuentameotro.com/precios"
    style={{ 
        textDecoration: 'none',
        color: 'black',
    }}>
    Mi Cuento Único®
    </Link>
</Text>


{/* simuSOMBRA */}
 <Text
 style={{ 
//     textAlign: 'center',
//  marginLeft: '30px',
//  marginRight: '30px',
lineHeight: '1.5px',
 position: "absolute",
 top: '23px',
         left: '1px',
        // right: '0px',
        marginHorizontal: 'auto',
        textAlign: "center",
        justifyContent: 'center',
        maxWidth: '370px',
        paddingLeft: '50px',
        paddingRight: '57px',
        fontFamily:'Roboto',
        color: 'white',
        fontSize: '18px',        // border: 'solid',
        // borderColor: 'white',
        // textShadow: '1px 1px 1px #fff, -1px -1px 1px #fff, 1px -1px 1px #fff, -1px 1px 1px #fff',
}}
>{RespuestaX}</Text>



          <Text
 style={{ 
//     textAlign: 'center',
//  marginLeft: '30px',
//  marginRight: '30px',
lineHeight: '1.5px',
 position: "absolute",
 top: '22px',
        // left: '0px',
        // right: '0px',
        marginHorizontal: 'auto',
        textAlign: "center",
        justifyContent: 'center',
        maxWidth: '370px',
        paddingLeft: '50px',
        paddingRight: '57px',
        fontFamily:'Roboto',
        fontSize: '18px',  
        // border: 'solid',
        // borderColor: 'white',
        // textShadow: '1px 1px 1px #fff, -1px -1px 1px #fff, 1px -1px 1px #fff, -1px 1px 1px #fff',
}}
>{RespuestaX}</Text>



<Link src="https://cuentameotro.com/precios"
    style={{ 
        position: "absolute",
        textDecoration: 'none',
        color: 'black',
        display: "flex", 
        alignSelf: "center",
        justifyContent: 'center',
        // bottom: '50px',
        fontSize: '62px',
        top: '725px',
        color: 'transparent',
    }}>
    IM
    </Link>



</View>
         </Page>
    </Document>

  )
}
