
function Nombres({ onNombreChange }) {
    return (
      <div className="otra">
      <div className='flexi'>
      <div className='train1'>
        
             <div className='flexi'>
             
             
          <input  className='texteo' type="text"  onChange={onNombreChange} placeholder="Nombre de tu hij@" />
      
             </div>
             </div>
             </div>
      </div>
    );
  }
  export default Nombres;
  