
export const quiereComprarAppEvent = () => {
    window.fbq('trackCustom', 'ClickVamosAlla-App');
}

export const compraCorrectaAppEvent = () => {
    window.fbq('trackCustom', 'CompraExitoBien-App');
}

export const signUpSuccessAppEvent = () => {
    window.fbq('trackCustom', 'RegistroNeutroBien-App');
}



export const signUpBuySuccessAppEvent = () => {
    window.fbq('trackCustom', 'RegistroBuyBien-App');
}


