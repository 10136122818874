import { useRef, useState } from 'react'
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from '../contexts/AuthContext.js'
import { Link, useNavigate } from 'react-router-dom';

import logoImg from '../assets/logo1.png';

export default function UpdateProfile() {

    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const { currentUser, updatePassword, updateEmail } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

     function handleSubmit(e) {
         e.preventDefault()

          const passworde = passwordRef.current.value;

       

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
        return setError('Las contraseñas no coinciden')
     } 



if (passworde && passworde.length > 0) {
    if (passworde.length < 6) {
      setError('La contraseña debe tener al menos 6 caracteres');
      return;
    }
  
    if (!/[A-Z]/.test(passworde)) {
      setError('La contraseña debe contener al menos una letra mayúscula');
      return;
    }
  
    if (!/\d/.test(passworde)) {
      setError('La contraseña debe contener al menos un número');
      return;
    }
  }
  
  

     const promises = []
     setLoading(true) 
     if (emailRef.current.value !== currentUser.email) {
        promises.push(updateEmail(emailRef.current.value))
     }
     if (passwordRef.current.value) {
        promises.push(updatePassword(passwordRef.current.value))
     }

     Promise.all(promises).then(() => {
        navigate("/")
     }).catch(() => {
        setError('Error al actualizar perfil')
     }).finally(() => {
         setLoading(false)
     })

         




    }

  return (
    <>

    <Card className='bor'>
    <Card.Body>
        <div className="imo">
      
        <img src={logoImg} alt='' width="130" height="130"/>
      
        </div>
        <h2 className="text-center mb-4">Configuración</h2>
        {error && <Alert variant='danger'>{error}</Alert>}
      <Form onSubmit={handleSubmit}>

       
        <Form.Group id="email">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" ref={emailRef} required defaultValue={currentUser.email} /> 
        </Form.Group>
        <Form.Group id="password">
          <Form.Label>Contraseña</Form.Label>
          <Form.Control type="password" ref={passwordRef} placeholder='Deja en blanco para mantenerla igual' />
        </Form.Group>
        <Form.Group id="password-confirm">
          <Form.Label>Confirmación de contraseña</Form.Label>
          <Form.Control type="password" ref={passwordConfirmRef} placeholder='Deja en blanco para mantenerla igual' />
        </Form.Group>
        <Button disabled={loading} className="w-100 mt-2 btn-dark" type="submit">
              Actualizar
            </Button>
      </Form>
    </Card.Body>
  </Card>

    <div className="w100 text-center mt-3">
        <Link to="/">Cancelar</Link>
    </div>
    </>
  )
}
