import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext.js';
import { Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import AmpliaCuento from './open/AmpliaCuento';
import VerAudio2 from './eleven/VerAudio2';
import logoImg from '../assets/logo1.png';
import VerPDF from './VerPDF';

import boca from '../assets/escuchar.png';
import amplia from '../assets/ampliar.png';

export default function MisCuentos() {



  const { currentUser } = useAuth();
  const clienteUID = currentUser.uid;
  const clienteRef = db.collection('Clientes').doc(clienteUID);
  const [respuestas, setRespuestas] = useState([]);

  const [selectedRespuesta, setSelectedRespuesta] = useState(null); 

  const [selectedRespuesta2, setSelectedRespuesta2] = useState(null); 
  
  const [selectedRespuesta3, setSelectedRespuesta3] = useState(null); 


  const [respuestasVisible, setRespuestasVisible] = useState(true);


  const handleAmpliarCuentoClick = (respuesta) => {
    setSelectedRespuesta(respuesta);
    setRespuestasVisible(false);
    console.log('Ampliar Cuento clicked:', respuesta);
  };


  const handleEscucharCuentoClick = (respuesta) => {
    setSelectedRespuesta2(respuesta);
    setRespuestasVisible(false);
    console.log('Escuchar Cuento clicked:', respuesta);
  };

  const handleDescargarCuentoClick = (respuesta) => {
    setSelectedRespuesta3(respuesta);
    setRespuestasVisible(false);
    console.log('Descargar Cuento clicked:', respuesta);
  };



  const copiarTextoAlPortapapeles = async (texto) => {
    try {
      await navigator.clipboard.writeText(texto);
      alert('Texto copiado al portapapeles.');
    } catch (error) {
      console.error('Error al copiar al portapapeles:', error);
    }
  };

  
  useEffect(() => {
    const obtenerRespuestas = async () => {
      try {
        const respuestasRef = clienteRef.collection('Respuestas');
        const respuestasSnapshot = await respuestasRef.get();

        if (!respuestasSnapshot.empty) {
          const respuestasData = respuestasSnapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              Respuesta: data.Respuesta,
              Nombre: data.Nombre,
              Gusto: data.Gusto,
              Concepto: data.Concepto,
            };
          });
          setRespuestas(respuestasData);
        } else {
          console.log('El cliente no tiene respuestas almacenadas en la subcolección.');
        }
      } catch (error) {
        console.error('Error al obtener respuestas:', error);
      }
    };

    obtenerRespuestas();
  }, [clienteRef]);
      

  // return (
    return (
      <div className="conRes">

          <div className='d-flex align-items-center justify-content-center mt-2'>
        <Link to="/"><img src={logoImg} alt='' width="150" height="150" className=''/></Link>
        </div>
        <h2 className="text-center ">Mis Cuentos</h2>
        {respuestasVisible && (
        <div className="">
          {respuestas.length > 0 ? (
            respuestas.map((respuesta, index) => (
              <div key={index} className="card m-4 bor esca lihe">
                <div className="card-body">
                <h4 className='text-center'>{respuesta.Nombre} | {respuesta.Concepto} | {respuesta.Gusto}</h4>
                <p className="card-text text-center">{respuesta.Respuesta}</p>
                  <div className="d-flex align-items-center justify-content-center">
                    <button
                      className="btn btn-secondary"
                      onClick={() => copiarTextoAlPortapapeles(respuesta.Respuesta)}
                    >
                       <i className="fas fa-copy"></i>

                    </button>
                    <button
                      className="btn btn-dark ms-2"
                      onClick={() => handleAmpliarCuentoClick(respuesta.Respuesta)}
                    >
                     {/* <i className="fas fa-file-circle-plus"></i> */}
                     <img src={amplia} width={'30px'}></img>
                    </button>
                 
                    <button
                      className="btn btn-dark ms-2"
                      onClick={() => handleEscucharCuentoClick(respuesta.Respuesta)}
                    >
                      {/* 🎧 */}
                       {/* <i className="fas fa-headphones"></i> */}
                       <img src={boca} width={'30px'}></img>
                    </button>

                    <button
                      className="btn btn-secondary ms-2"
                      onClick={() => handleDescargarCuentoClick(respuesta.Respuesta)}
                    >
                      {/* <i className="fas fa-download"></i> */}
                       {/* <i className="fas fa-file-pdf"></i> */}
                      <i className="fas fa-file-arrow-down"></i>
                    </button>

                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center mt-4">No hay respuestas disponibles.</div>
          )}
        </div>


)}
  {selectedRespuesta && <AmpliaCuento Respuesta1={selectedRespuesta} />}
        {selectedRespuesta2 && <VerAudio2 Respuesta1={selectedRespuesta2} />}
        {selectedRespuesta3 && <VerPDF Respuesta1={selectedRespuesta3} />}
        <div className="canto  d-flex comof justify-content-center align-items-center">
<div className="mb-4 w-75">
        <Link to="/" className="btn btn-light w-100 mt-3 mb-1">
          INICIO
        </Link>
        <Link to="/MisCuentosAmpliados" className="btn btn-success w-100 mt-2 mb-1">
          CUENTOS AMPLIADOS
        </Link>
        <Link to="/misaudios" className="btn btn-dark w-100 mt-2">
          AUDIO-CUENTOS
        </Link>
        </div>
        </div>
      </div>
    );
  }
