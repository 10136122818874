import React, { useState, useEffect } from 'react';
import { Card, Button, Alert } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext.js';
import { Link, useNavigate } from 'react-router-dom';

import { db, analytics } from '../firebase';

import { getAnalytics, logEvent } from "firebase/analytics";

import logoImg from '../assets/logo1.png';
import boca from '../assets/escuchar.png';
import amplia from '../assets/ampliar.png';
import crea from '../assets/historia.png';

export default function Dashboard() {
const [error, setError] = useState("")
const { currentUser, logout } = useAuth()
const navigate = useNavigate()


const clienteUID = currentUser.uid
const clienteRef2 = db.collection('Clientes').doc(clienteUID)
const [creditoss, setCreditoss] = useState(0); 
const [creditossA, setCreditossA] = useState(0); 
const [creditossE, setCreditossE] = useState(0); 
const [nombrE, setNombrE] = useState(''); 


const currentPath = window.location.pathname;
const analytics = getAnalytics();


useEffect(() => {
    const SETEARCRED = async ()  => {
      const clienteDoc2 = await clienteRef2.get();
  
      if (clienteDoc2.exists) {
        const creditos = clienteDoc2.data().Creditos;
        const creditosA = clienteDoc2.data().CreditosA;
        const creditosE = clienteDoc2.data().CreditosE;
        const nombrE = clienteDoc2.data().nombre;
        setCreditoss(creditos);
        setCreditossA(creditosA);
        setCreditossE(creditosE);
        setNombrE(nombrE);
        console.log('Creditos actuales:', creditos);
        console.log('Creditoss actuales:', creditoss);
      }}
      SETEARCRED();



      clienteRef2.get().then((doc) => {
        if (doc.exists) {
          const nombre = doc.data().nombre;
          const apellidos = doc.data().apellidos;
          const fexa =  new Date();
          const lola = `Nombre: ${nombre} ${apellidos} | Página: ${currentPath} | Fecha: ${fexa} | UserUID: ${clienteUID}`;
          const clien = `Nombre: ${nombre} ${apellidos} | UserUID: ${clienteUID}`
          logEvent(analytics, 'page_view', {
            COMPLETO: lola,
            CLIENTE: clien,
          });
        } else {
          console.log('El documento del cliente no existe.');
        }
      });


    },[]

  );



    async function handleLogout(){
        setError('')

        try {
            await logout()
            navigate('/login')
        } catch {
            setError('Error al cerrar sesión')            
        }
    }

  return (
   <>
   <Card className='bor covr'>
        <Card.Body>
          <div className='d-flex align-items-center justify-content-center'>
        <img src={logoImg} alt='' width="130" height="130"/>
        </div>
        <h2 className="text-center mb-4"><strong>{nombrE}</strong></h2>
        {error && <Alert variant='danger'>{error}</Alert>}
<div className="d-flex justify-content-center gap-4">
  <div className="minibox esca">
     <h3 className='text-center'>{creditoss} <img src={crea} width={'50px'}></img></h3>
     </div>
        <div className="minibox esca">
        <h3 className='text-center'>{creditossA} <img src={amplia} width={'50px'}></img></h3>
 </div>
<div className="minibox esca">
        <h3 className='text-center'>{creditossE} <img src={boca} width={'50px'}></img></h3>
         </div>
        </div>
        <Link to="/opencito" className="btn btn-primary w-100 mt-3 hoverlinea" >Crear Cuento</Link>
        <Link to="/miscuentos" className="btn btn-warning w-100 mt-3 hoverlinea" >Mis Cuentos</Link>
        <Link to="/precios" className="btn btn-success w-100 mt-3 hoverlinea" >Comprar Cuentos</Link>
        <Link to="/update-profile" className="btn btn-secondary w-100 mt-3 hoverlinea">Configurar Perfil</Link>

        
        </Card.Body>
   </Card>
   <div className="w100 text-center mt-3">
       <Button variant="link" onClick={handleLogout}>Cerrar sesión</Button>
    </div>
   </>
  )
}
