import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { db, storage, audioDb } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';

import { ref, uploadBytes, getStorage, getDownloadURL } from 'firebase/storage';

import loadingImage from '../../assets/loco.svg';
import boca from '../../assets/escuchar.png';
import { Button } from 'react-bootstrap';

export default function VerAudio2({ Respuesta1 }) {


    const text = Respuesta1;
    const [loading, setLoading] = useState(false);
    const [audioUrl, setAudioUrl] = useState('');
  
    const [voiceNum, setVoiceNum] = useState('voz1');

    const { currentUser } = useAuth()
    const clienteUID = currentUser.uid
    const clienteRef2 = db.collection('Clientes').doc(clienteUID)
  
    const [creditoss, setCreditoss] = useState(0); 

    const [btnVisible, setBtnVisible] = useState(true);

    const [errVisible, setErrVisible] = useState(false);

  

    useEffect(() => {
      const SETEARCRED = async ()  => {
        const clienteDoc2 = await clienteRef2.get();
    
        if (clienteDoc2.exists) {
          const creditos = clienteDoc2.data().CreditosE;
          setCreditoss(creditos)
          console.log('Creditos actuales:', creditos);
          console.log('Creditoss actuales:', creditoss);
        }}
        SETEARCRED();
      },[]

    );



    const restarCred = async () => {
      try {
        const clienteDoc2 = await clienteRef2.get();
    
        if (clienteDoc2.exists) {
          const creditos = clienteDoc2.data().CreditosE;
    
          console.log('Creditos antes de la deducción:', creditos);
    
          if (creditos > 0) {
            await clienteRef2.update({
              CreditosE: creditos - 1,
            });
    
            console.log('Creditos después de la deducción:', creditos - 1);
            setCreditoss(creditos - 1); 
          } else {
            console.log('El cliente no tiene suficientes créditos.');
          }
        } else {
          console.log('El cliente no tiene un documento en la base de datos.');
        }
      } catch (error) {
        console.error('Error al deducir créditos:', error);
      }
    };


   



    const guardarRespuestaEnStorage = async (downloadUrl) => {
      try {
        await clienteRef2.collection('AudiosUser').add({
          Fecha: new Date(),
          AudioUrl: downloadUrl, 
          CuentoParaEscuchar: Respuesta1,
        });

        console.log('Respuesta guardada en Firebase Storage y en Firestore');
      } catch (error) {
        console.error('Error al guardar la respuesta en Firebase Storage o Firestore:', error);
      }
    };





    const handleTextToSpeech = async () => {
      setLoading(true);
      setBtnVisible(false);
      try {
        const clienteDoc2 = await clienteRef2.get();

        if (clienteDoc2.exists) {
          const creditos = clienteDoc2.data().CreditosE;

          console.log('creditos:', creditos);

          setCreditoss(creditos);


          if (creditos > 0) {

            const response = await axios.get('https://labs.blockseal.net/text-to-speech', {
              params: { text, voiceNum },
              responseType: 'blob', 
            });
            const blob = new Blob([response.data], { type: 'audio/mpeg' });
            setAudioUrl(URL.createObjectURL(blob));


              setLoading(false);
              console.log('respuesta exitosa')
              await clienteRef2.update({
                CreditosE: creditos - 1,
                
                
              });
                  const storage = getStorage();
            const storageRef = ref(storage, `audio/${clienteUID}/${new Date().getTime()}.mp3`);
            await uploadBytes(storageRef, blob);

            const downloadUrl = await getDownloadURL(storageRef);
            await guardarRespuestaEnStorage(downloadUrl);

              console.log("crédito restado")
              setCreditoss(creditos-1);
              
         
          } else {
            console.error('El cliente no tiene suficientes créditos para realizar la solicitud.');
            setLoading(false);
            setErrVisible(true);
          }
        } else {
          console.error('El documento del cliente no existe.');
        }
      } catch (error) {
        console.error('Error al conectar con el servidor Express o al consultar Firestore:', error);
      }
      
   
    };


    const handleRefreshClick = () => {
      window.location.reload();
    };
    
  

  return (

    
    <div className="otrero">
    <div className='contecito'>
      <p className='text-center'>{Respuesta1}</p>
      <h3 className='text-center'>{creditoss} <img src={boca} width={'50px'}/></h3>


      {errVisible && (
<div className="comof d-flex align-items-center justify-content-center bg-warning p-3 bor">
  <p className=''>NO TIENES SUFICIENTES CRÉDITOS</p>
  <a href='/precios' className='btn-success btn w-100 mt-3 mb-2'>COMPRAR</a>
</div>
      )}


<div className="conton">
{btnVisible && (
<div className="conton2">
  
      <div className="cosa1 d-flex gap-3 justify-content-center">
      <form className='d-flex align-items-center justify-content-center'>
       
        <Button type="button" className='btn-dark' onClick={handleTextToSpeech}>ESCUCHAR</Button>
        
      </form>
      </div>

      <div className="cosa2 d-flex align-items-center justify-content-center">
      <div>
        <label>
          <input
            type="radio"
            value="voz1"
            checked={voiceNum === 'voz1'}
            onChange={() => setVoiceNum('voz1')}
          />
          🧞‍♂️
        </label>
      </div>

      <div>
        <label>
          <input
            type="radio"
            value="voz2"
            checked={voiceNum === 'voz2'}
            onChange={() => setVoiceNum('voz2')}
          />
         🧚🏼‍♀️
        </label>
      </div>
      </div>
      </div>
)}


      </div>


      <div className="comof d-flex justify-content-center align-items-center">
        <div className="keloks d-flex justify-content-center">
      {loading &&  <img className='imig' src={loadingImage} alt="Cargando..." />}
      </div>
      <div className="keloka d-flex justify-content-center">
      {audioUrl && (
        <audio controls>
          <source src={audioUrl} type="audio/mpeg" />
          Tu navegador no soporta el elemento de audio.
        </audio>
      )}
      </div>
      </div>
      
    </div>
    <a className='btn-warning btn w-100 mt-3 mb-2' onClick={handleRefreshClick}>MIS CUENTOS</a>
    </div>
  )
}

