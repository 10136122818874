import '../../App2.css';
import Nombres from './Nombres';
import Concepto from './Concepto';
import Gusto from './Gusto';
import Idioma from './Idioma';
import Result from './Result';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';





export default function Opencito() {
    const [NombreSeleccionado, setNombreSeleccionado] = useState('');
    const [ConceptoSeleccionado, setConceptoSeleccionado] = useState('');
    const [GustoSeleccionado, setGustoSeleccionado] = useState('');
    const [IdiomaSeleccionado, setIdiomaSeleccionado] = useState('');
    const [step, setStep] = useState(1);
  
    const [nombreCompleto, setNombreCompleto] = useState(false);
    const [conceptoCompleto, setConceptoCompleto] = useState(false);
    const [gustoCompleto, setGustoCompleto] = useState(false);
    const [idiomaCompleto, setIdiomaCompleto] = useState(false);



    useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.keyCode === 13) {
            if (step === 1 && nombreCompleto) {
              setStep(2);
            } else if (step === 2 && conceptoCompleto) {
              setStep(3);
            } else if (step === 3 && gustoCompleto) {
              setStep(4);
            }
            else if (step === 4 && idiomaCompleto) {
              setStep(5);
            }
          }
        };
    
    
        document.addEventListener('keydown', handleKeyDown);
    
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, [step, nombreCompleto, conceptoCompleto, gustoCompleto, idiomaCompleto]);
    
      
    
      
    
      const handleNombreChange = (event) => {
        setNombreSeleccionado(event.target.value);
        setNombreCompleto(event.target.value.trim() !== ''); 
      };
    
      const handleConceptoChange = (event) => {
        setConceptoSeleccionado(event.target.value);
        setConceptoCompleto(event.target.value.trim() !== ''); 
      };
    
      const handleGustoChange = (event) => {
        setGustoSeleccionado(event.target.value);
        setGustoCompleto(event.target.value.trim() !== '');
      };

      const handleIdiomaChange = (event) => {
        setIdiomaSeleccionado(event.target.value);
        setIdiomaCompleto(event.target.value.trim() !== '');
      };

    
      const logNombreValue = () => {
         console.log('Iniciando logNombreValue');
         console.log('Paso actual:', step);
        console.log(NombreSeleccionado, ConceptoSeleccionado, GustoSeleccionado, IdiomaSeleccionado);
        if (step === 1 && nombreCompleto) {
          setStep(2);
        } else if (step === 2 && conceptoCompleto) {
          setStep(3);
        } else if (step === 3 && gustoCompleto) {
          setStep(4);
        }
       else if (step === 4 && idiomaCompleto) {
        setStep(5);
      }
      };
    
        console.log('Renderizando componente App'); 
      console.log('Valor de step en App:', step);


      return (
        <div className="App">
    
          <div className="siqueflex">
          
              <div className="nomyBot">
              <div className='containero'>
              <div className="input-button-container">
              {step === 1 && <Nombres onNombreChange={handleNombreChange} />}
              {step === 2 && <Concepto onConceptoChange={handleConceptoChange} />}
              {step === 3 && <Gusto onGustoChange={handleGustoChange} />}
              {step === 4 && <Idioma onIdiomaChange={handleIdiomaChange} />}
              {step === 5 && <Result 
              NombreSeleccionado={NombreSeleccionado}
            GustoSeleccionado={GustoSeleccionado}
            ConceptoSeleccionado={ConceptoSeleccionado}
            IdiomaSeleccionado={IdiomaSeleccionado}/>}
                 <button id='subM' className={`circular-button ${step === 5 ? 'oculto' : ''}`} onClick={logNombreValue}>
            <i className="fas fa-arrow-right"></i>
          </button>
    
          
    
               </div>
               </div>
         <div className="conten">

         {step !== 5 && (
         <div className="menuu d-flex">
         <div className="roww">
                    <div className={`menu-item one ${step === 1 ? 'selected' : ''}`}></div>
                    <div className={`menu-item tre ${step === 4 ? 'selected' : ''}`}></div>
                    
                  </div>
                  <div className="roww">
                  <div className={`menu-item due ${step === 2 ? 'selected' : ''}`}></div>
                  <div className={`menu-item cua ${step === 3 ? 'selected' : ''}`}></div>
                 
                  
                  </div>
        </div>
         )}

        <Link to="/" className="btn btn-light w-100 mt-3" >INICIO</Link>
         </div>
         </div>
         </div>
         
         
        
        </div>
      );
    }