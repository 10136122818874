import React, { useEffect, useState, } from 'react';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import loadingImage from '../../assets/loco.svg';
import '../../App2.css';
import { Button } from "react-bootstrap";

function Result({ NombreSeleccionado, ConceptoSeleccionado, GustoSeleccionado, IdiomaSeleccionado }) {

  const { currentUser } = useAuth()
  const clienteUID = currentUser.uid
  const clienteRef = db.collection('customers').doc(clienteUID)
  const clienteRef2 = db.collection('Clientes').doc(clienteUID)
  const prodRef = db.collection('products').doc("variable id del producto")

  const [resultadoText, setResultadoText] = useState('');
  const [loading, setLoading] = useState(true); 
  const [copiado, setCopiado] = useState(false);

  const [creditoss, setCreditoss] = useState(0); 
  const [products, setProducts] = useState([])

  const [errVisible, setErrVisible] = useState(false);





  useEffect(() => {
    const handleEnviarAOpenAI = async () => {
      console.log('clienteUID:', clienteUID);
      try {
        const clienteDoc2 = await clienteRef2.get();

        console.log('clienteDoc.exists:', clienteDoc2.exists);

        if (clienteDoc2.exists) {
          const creditos = clienteDoc2.data().Creditos;

          console.log('creditos:', creditos);

          setCreditoss(creditos);

          if (creditos > 0) {
            const requestData = {
              message: `Breve fábula para mi hijo: ${NombreSeleccionado}, quiero enseñarle sobre ${ConceptoSeleccionado}, y le gusta ${GustoSeleccionado}. El idioma debe ser ${IdiomaSeleccionado}.`,
            };

            console.log('Realizando solicitud al servidor Express...');
            const response = await fetch('https://nodee.blockseal.net/api/openai', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestData),
            });

            if (response.ok) {
              const data = await response.json();
              setResultadoText(data.response);
              setLoading(false);
              console.log('Respuesta del servidor Express:', data.response);

              await clienteRef2.update({
                Creditos: creditos - 1,
                
                
              });

              await clienteRef2.collection('Respuestas').add({
              
                Fecha: new Date(),
                Nombre: NombreSeleccionado,
                Concepto: ConceptoSeleccionado,
                Gusto: GustoSeleccionado,
                Idioma: IdiomaSeleccionado,
                Respuesta: data.response,
              });

              console.log("crédito restado")
              setCreditoss(creditos-1);
              
            } else {
              console.error('Error en la respuesta del servidor Express:', response.status);
            }
          } else {
            console.error('El cliente no tiene suficientes créditos para realizar la solicitud.');

            setLoading(false);
            setErrVisible(true);
          }
        } else {
          console.error('El documento del cliente no existe.');
        }
      } catch (error) {
        console.error('Error al conectar con el servidor Express o al consultar Firestore:', error);
      }
    };

    handleEnviarAOpenAI();
  }, [NombreSeleccionado, ConceptoSeleccionado, GustoSeleccionado, IdiomaSeleccionado]);

  const copiarTextoAlPortapapeles = () => {
    if (resultadoText) {
      navigator.clipboard.writeText(resultadoText)
        .then(() => {
          setCopiado(true);
        })
        .catch((error) => {
          console.error('Error al copiar al portapapeles:', error);
        });
      }
    };

  return (
    <div className="result">
    <h2>Resultado:</h2>
    <h3>Creditos actuales: {creditoss}</h3>
    <div className="d-flex">

    {errVisible && (
<div className="comof d-flex align-items-center justify-content-center bg-warning p-3 bor">
  <p className=''>NO TIENES SUFICIENTES CRÉDITOS</p>
  <a href='/precios' className='btn-success btn w-100 mt-3 mb-2'>COMPRAR</a>
</div>
      )}

    {loading ? (
      <img className='imig' src={loadingImage} alt="Cargando..." />
    ) : (
      <>
      <div className="boxo">
        <div className='apii'>{resultadoText}</div>
        {copiado ? (
          <p>Texto copiado al portapapeles</p>
          
        ) : (
          
          <Button className='btn-secondary btn-sm mb-2' onClick={copiarTextoAlPortapapeles}>Copiar</Button>
          
        
          
        )}
      </div>
      </>
    )}
    </div>
    <div className="gap-3 d-flex justify-content-center align-items-center">

  </div>
  </div>
);


}

export default Result;
