import React, { useEffect, useState, } from 'react';
import '../../App2.css';
import loadingImage from '../../assets/loco.svg';
import ampli from '../../assets/ampliar.png';
import { db } from '../../firebase'
import { useAuth } from '../../contexts/AuthContext';
import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom';

function AmpliaCuento({ Respuesta1 }) {
    

  const { currentUser } = useAuth()
  const clienteUID = currentUser.uid
  const clienteRef = db.collection('customers').doc(clienteUID)
  const clienteRef2 = db.collection('Clientes').doc(clienteUID)
  const prodRef = db.collection('products').doc("variable id del producto")

  const [resultadoText, setResultadoText] = useState('');
  const [loading, setLoading] = useState(false); 
  const [copiado, setCopiado] = useState(false);

  const [creditoss, setCreditoss] = useState(0); 
  const [products, setProducts] = useState([])


  const [respuestasVisible, setRespuestasVisible] = useState(false);
  const [btnVisible, setBtnVisible] = useState(true);

  const [errVisible, setErrVisible] = useState(false);


  useEffect(() => {
    const SETEARCRED = async ()  => {
      const clienteDoc2 = await clienteRef2.get();
  
      if (clienteDoc2.exists) {
        const creditos = clienteDoc2.data().CreditosA;
        setCreditoss(creditos)
        console.log('Creditos actuales:', creditos);
        console.log('Creditoss actuales:', creditoss);
      }}
      SETEARCRED();
    },[]
  );



const sumarCredito2 = async () => {
  try {
    const clienteDoc2 = await clienteRef2.get();
    if (clienteDoc2.exists) {
      const creditos = clienteDoc2.data().Creditos;
      await clienteRef2.update({
        Creditos: creditos + 1,
      });
      setCreditoss(creditos + 1); 
      console.log('Crédito sumado');
    } else {
      console.error('El documento del cliente no existe.');
    }
  } catch (error) {
    console.error('Error al sumar crédito en Firestore:', error);
  }
};






    const handleEnviarAOpenAI = async () => {
      setLoading(true);
      setBtnVisible(false);
      try {
        const clienteDoc2 = await clienteRef2.get();
        if (clienteDoc2.exists) {
          const creditos = clienteDoc2.data().CreditosA;
          setCreditoss(creditos);

          if (creditos > 0) {
            const requestData = {
              message: `Continúa este relato con 120 palabras  más: ${Respuesta1}`,
            };
            console.log('Relato:', Respuesta1);
            console.log('Solicitud a OpenAI:', requestData);
            const response = await fetch('https://nodee.blockseal.net/api/openai', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestData),
            });

            if (response.ok) {
              const data = await response.json();
              setResultadoText(data.response);
              setLoading(false);
              console.log('Respuesta del servidor Express:', data.response);

              await clienteRef2.update({
                CreditosA: creditos - 1,
              });




              await clienteRef2.collection('RespuestaAmplia').add({
              
                Fecha: new Date(), 
                CuentoParaAmpliar: Respuesta1,
                PromptEnviado: requestData,
                CuentoAmpliado: data.response,
                Sumatorio: `${Respuesta1} ${data.response}`,
              });

              console.log("crédito restado")
              setCreditoss(creditos - 1);
              setRespuestasVisible(true);
            } else {
              console.error('Error en la respuesta del servidor Express:', response.status);
            }
          } else {
            console.error('El cliente no tiene suficientes créditos para realizar la solicitud.');
            setLoading(false);
            setErrVisible(true);
          }
        } else {
          console.error('El documento del cliente no existe.');
        }
      } catch (error) {
        console.error('Error al conectar con el servidor Express o al consultar Firestore:', error);
      }
    };





  const copiarTextoAlPortapapeles = () => {
    if (resultadoText) {
      navigator.clipboard.writeText(resultadoText)
        .then(() => {
          setCopiado(true);
        })
        .catch((error) => {
          console.error('Error al copiar al portapapeles:', error);
        });
      }
    };

    const handleRefreshClick = () => {
      window.location.reload();
    };
    

  return (
    <div className="result">
       <div className='contecito'>
      <p className='text-center'>{Respuesta1}</p>
      <h3 className='text-center'>{creditoss} <img src={ampli}  width={'50px'}/></h3>
      <div className="cosa1 d-flex gap-3 justify-content-center">
      {errVisible && (
<div className="comof d-flex align-items-center justify-content-center bg-warning p-3 bor">
  <p className=''>NO TIENES SUFICIENTES CRÉDITOS</p>
  <a href='/precios' className='btn-success btn w-100 mt-3 mb-2'>COMPRAR</a>
</div>
      )}
      <form className='d-flex align-items-center justify-content-center'>
      {btnVisible && (
      <Button type="button" className='btn-dark' onClick={handleEnviarAOpenAI}>AMPLIAR</Button>
      )}
      </form>
      </div>
      
    <div className="d-flex justify-content-center">
    {loading ? (
      <img className='imig ms-5' src={loadingImage} alt="Cargando..." />
    ) : (
      <>
      {respuestasVisible && (
      <div className="boxo">
      
        <div className="jaja">
        <h4 className='text-center'>Cuento Ampliado</h4>
        <div className='apii esca'>{Respuesta1}{resultadoText}</div>
        </div>
      
        <div className="d-flex justify-content-center align-items-center">
        {copiado ? (
          <p>Texto copiado al portapapeles</p>
          
        ) : (
          
          <Button className='btn-secondary btn-sm mb-2' onClick={copiarTextoAlPortapapeles}>Copiar</Button>
          
        
          
        )}
        
        </div>

        
      </div>
      )}
      </>
    )}
    </div>
    <div className="gap-3 d-flex justify-content-center align-items-center">
  </div>
  </div>

      <a className='btn-warning btn w-100 mt-3 mb-2' onClick={handleRefreshClick}>MIS CUENTOS</a>
  </div>
);


}

export default AmpliaCuento;
