import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert, Container } from "react-bootstrap";
import { useAuth } from '../contexts/AuthContext.js'
import { Link, useNavigate } from 'react-router-dom';
import Reviews from '../components/novisit/Reviews.js';
import logoImg from '../assets/logo1.png';


export default function SignBuy() {

    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const { signup } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const nombreRef = useRef()
    const apeRef = useRef()




  

   


    async function handleSubmit(e) {
        e.preventDefault()

        const passworde = passwordRef.current.value;
        const nombreeee = nombreRef.current.value;
        const apell = apeRef.current.value;

        if (passworde.length < 6) {
          setError('La contraseña debe tener al menos 6 caracteres');
          return;
        }

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Las contraseñas no coinciden')
        } 

        if (!/[A-Z]/.test(passworde)) {
          setError('La contraseña debe contener al menos una letra mayúscula');
          return;
        }

        if (!/\d/.test(passworde)) {
          setError('La contraseña debe contener al menos un número');
          return;
        }

        try{
            setError('')
            setLoading(true)
       await signup(emailRef.current.value, passwordRef.current.value)




       navigate("/C0D1E2F3gH4I5J6K7lM8N9O0P1Q2rS3?nombre=" + nombreeee+ "&apellidos=" + apell);

        } catch {
            setError('Error al crear la cuenta')
        }

        setLoading(false)
    }






  return (
    <>
<Container className=''>

<Card className='mb-4 mt-4 p-3 bor esca covr'>
    <Card.Body>

      <h3>    <i className="fa-solid fa-lightbulb me-2 icoo"></i> Ahora conseguirás:</h3>
    <ul className="fa-ul sinmarbo">
    <li className='mb-3 mt-3'><span className="fa-li"><i className="fas fa-check"></i></span><strong>Crear 3 relatos totalmente únicos, personalizados y educativos.</strong></li>
     
    <li className='mb-3'><span className="fa-li"><i className="fas fa-check"></i></span><strong>Ampliar 1 historia de las que has creado, para que sea un poco más larga.</strong></li>

                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span>Enseñar valores esenciales que elijas a través de narrativas cautivadoras basadas en un gustos personales.</li>
                            <li className=''><span className="fa-li"><i className="fas fa-check"></i></span><strong>El nova más: Crear 1 audio-cuento, narrado por quien elijas. (Por tiempo limitado)</strong></li>
                        </ul>
    </Card.Body>
    </Card>


    <Card className='bor'>
    <Card.Body>
        <div className="imo">
        <img src={logoImg} alt="" width="110" height="110"/>
        </div>


        <h2 className="text-center mb-4">1. Me Registro...</h2>


        {error && <Alert variant='danger'>{error}</Alert>}
      <Form onSubmit={handleSubmit}>

         <div className='gol'>
      <Form.Group  id="nombre">
          <Form.Label>Nombre</Form.Label>
          <Form.Control type="text" ref={nombreRef} required />
        </Form.Group>
        <Form.Group id="apellidos">
          <Form.Label>Apellidos</Form.Label>
          <Form.Control type="text"ref={apeRef}  required />
        </Form.Group>
        </div>
         
        <Form.Group id="email">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" ref={emailRef} required />
        </Form.Group>
        <Form.Group id="password">
          <Form.Label>Contraseña</Form.Label>
          <Form.Control type="password" ref={passwordRef} required />
        </Form.Group>
        <Form.Group id="password-confirm">
          <Form.Label>Confirmación de contraseña</Form.Label>
          <Form.Control type="password" ref={passwordConfirmRef} required />
        </Form.Group>
        

<h2 className="text-center mb-2">2. Lo Compro...</h2>

        <Button disabled={loading} className="w-100 mt-2 btn-success" type="submit">
             Comprar ahora
            </Button>

            <h2 className="text-center mt-4 mb-0">3. Lo Creo...</h2>

      </Form>
    </Card.Body>
  </Card>


    <Reviews></Reviews>
   

    </Container>


    </>
  )
}
