import { loadStripe } from '@stripe/stripe-js';
import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext.js';
import { Card, Button, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import loadingImage from '../../assets/loco.svg';
import logoImg from '../../assets/logo1.png';

import { useLocation } from 'react-router-dom';
import { signUpBuySuccessAppEvent } from '../novisit/facebookPixelEvent';

export default function SignCheck() {
    const [error, setError] = useState("")
    const { currentUser } = useAuth()
    const clienteUID = currentUser.uid
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);

    const clienteRef2 = db.collection('Clientes').doc(clienteUID)
    const [creditoss, setCreditoss] = useState(0); 

    const location = useLocation();
    const nombre = new URLSearchParams(location.search).get('nombre');
    const apelli = new URLSearchParams(location.search).get('apellidos');

    const publikey = process.env.REACT_APP_STRIPE_PUBLIC_API_KEY;

    const crearCreditos = async () => {
        const mail = currentUser.email;
        const Nombre = nombre;
        const Apellidos = apelli;
        try {
          await clienteRef2.set({
            nombre: Nombre,
            apellidos: Apellidos,
            email: mail,
            Creditos: 0,  
            CreditosA: 0,
            CreditosE: 0,
          });
          
          console.log('Campo "Creditos" creado en Firestore con valor 0');
          console.log('email', currentUser.email)
          setCreditoss(0)
        } catch (error) {
          console.error('Error al crear el campo "Creditos" en Firestore:', error);
        }
      };



    const checkOut = async(priceId)=>{
        console.log("Price ID:", priceId);
        const docRef = await db.collection("customers").doc(currentUser.uid).collection("checkout_sessions").add({
            mode: "payment",
            price: 'price_1O7YqOFjgzrP8mSqgEzTjoAV',    
            success_url:`${window.location.origin}/Y3zA4B5C6D7E8F9G0H1I2J3K4lM5N6`,
            cancel_url:`${window.location.origin}/precios`
        })
        console.log("Doc Ref:", docRef.id); 
        docRef.onSnapshot(async(snap) =>{
            console.log("Snapshot Data:", snap.data())
            const {error,sessionId} = snap.data();
            if (error) {
                console.log("Error:", error.message);
                alert(error.message)
            }
            if (sessionId) {
                console.log("Session ID:", sessionId);
                const stripe = await loadStripe(`${publikey}`);
                stripe.redirectToCheckout({ sessionId })
            }
        })
      }


      useEffect(() => {
        crearCreditos().then(() => {
          signUpBuySuccessAppEvent();
          checkOut();
        });
      }, [navigate]);
     

      if (loading) {
        return (
            <Card className='d-flex align-items-center justify-content-center'>
             <img src={logoImg} alt='' width="130" height="130"/>
            <img className='ms-5 display-4' src={loadingImage} alt="Cargando..."  width="120" height="120" />
          </Card>
        );
      }



  return (
    <div>
        <button onClick={checkOut}>DALE</button>
    </div>
    
  )
}
