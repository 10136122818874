
function Idioma({ onIdiomaChange }) {
    return (
      <div className="otra">
      <div className='flexi'>
      <div className='train1'>
        
             <div className='flexi'>
             
             <select className="texteo" onChange={onIdiomaChange}>
  <option value="">Idioma del cuento</option>
  <option value="Español">Castellano</option>
  <option value="Inglés">Inglés</option>
  <option value="Catalán">Catalán</option>
  <option value="Valenciano">Valenciano</option>
  <option value="Gallego">Gallego</option>
  <option value="Euskera">Euskera</option>
</select>
      
             </div>
             </div>
             </div>
      </div>
    );
  }
  export default Idioma;
  