
import React, { useEffect, useState, } from 'react';
import { db } from '../../firebase'
import { useAuth } from '../../contexts/AuthContext.js'
import { Link, useNavigate } from 'react-router-dom';
import loadingImage from '../../assets/loco.svg';
import { Form, Button, Card, Alert } from "react-bootstrap";

import logoImg from '../../assets/logo1.png';

import { compraCorrectaAppEvent } from '../novisit/facebookPixelEvent';

export default function Exito() {


    const { currentUser } = useAuth()
    const clienteUID = currentUser.uid
    const [products, setProducts] = useState([])
    const clienteRef2 = db.collection('Clientes').doc(clienteUID)
    const [creditoss, setCreditoss] = useState(0); 

    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);







    useEffect(() => {
        db.collection('products').where('active', '==', true).get().then(snapshot => {
          const products = {}
          snapshot.forEach(async productDoc => {
            products[productDoc.id] = productDoc.data()
            const priceSnapshot = await productDoc.ref.collection('prices').get();
            priceSnapshot.forEach(priceDoc => {
              products[productDoc.id].prices = {
                priceId: priceDoc.id,
                priceData: priceDoc.data()
              }
            })
      
            const creditosDeProd = productDoc.data().stripe_metadata_Cred
            console.log(`Producto ID: ${productDoc.id}, Cred: ${creditosDeProd}`);
            console.log(`${creditosDeProd}`); 
          })
      
          console.log('products', products)
          console.log('Object.entries', Object.entries(products))
          setProducts(products)
        })
      }, [])
      
 
const customerRef = db.collection('customers').doc(clienteUID);

customerRef.onSnapshot((customerDoc) => {
  const customerData = customerDoc.data();
  if (customerData) {
    console.log('Customer Data:', customerData);
    const paymentsCollection = customerRef.collection('payments');
      
    paymentsCollection.onSnapshot((paymentSnapshot) => {
      paymentSnapshot.docChanges().forEach((paymentChange) => {
        if (paymentChange.type === 'added' || paymentChange.type === 'modified') {
          const paymentData = paymentChange.doc.data();
            console.log('Payment Data:', paymentData);
           if (paymentData.status === 'succeeded') {

            const itemComprado = paymentData.items[0].description;
            const cantiReci = paymentData.amount_received;
            console.log('Item Comprado:', itemComprado);
            console.log('Cantidad pago recibido:', cantiReci);


            let creditosToAdd = 0;
            if (cantiReci === 295) {
              creditosToAdd = 3; 
            } else if (cantiReci === 495) {
              creditosToAdd = 6; 
            }
             else if (cantiReci === 795) {
            creditosToAdd = 10; 
          }
          let creditosToAdd2 = 0;
            if (cantiReci === 295) {
              creditosToAdd2 = 1; 
            } else if (cantiReci === 495) {
              creditosToAdd2 = 3; 
            }
             else if (cantiReci === 795) {
            creditosToAdd2 = 7; 
          }

          let creditosToAdd3 = 0;
          if (cantiReci === 295) {
            creditosToAdd3 = 1; 
            creditosToAdd3 = 2; 
          }
           else if (cantiReci === 795) {
          creditosToAdd3 = 4; 
        }

          


            sumarCredito2(creditosToAdd, creditosToAdd2, creditosToAdd3);

            compraCorrectaAppEvent();

            navigate("/")
            
            console.log('Payment was successful for Customer ID:', clienteUID);
            console.log('Payment Data:', paymentData);
            console.log('Item:', paymentData.items[0].description, 'Cantidad dinero:', paymentData.amount_received, 'Status:', paymentData.status)

           
          } else {
            console.log('Payment was not successful for Customer ID:', clienteUID);
          }
        }
      });
    });
  }
});







    const sumarCredito2 = async (creditosToAdd, creditosToAdd2, creditosToAdd3) => {
        try {
          const clienteDoc2 = await clienteRef2.get();
          if (clienteDoc2.exists) {
            const creditos = clienteDoc2.data().Creditos;
            const creditosA = clienteDoc2.data().CreditosA;
            const creditosE = clienteDoc2.data().CreditosE;
            await clienteRef2.update({
              Creditos: creditos + creditosToAdd,
              CreditosA: creditosA + creditosToAdd2,
              CreditosE: creditosE + creditosToAdd3,
            });
            setCreditoss(creditos + creditosToAdd); 
            console.log(creditosToAdd, ' créditos sumados de crear');
            console.log(creditosToAdd2, ' créditos sumados de ampliar');
            console.log(creditosToAdd3, ' créditos sumados de escuchar');
          } else {
            console.error('El documento del cliente no existe.');
          }
        } catch (error) {
          console.error('Error al sumar crédito en Firestore:', error);
        }
      };     

      if (loading) {
        return (
            <Card className='d-flex align-items-center justify-content-center'>
               <img src={logoImg} alt='' width="130" height="130"/>
            <img className='ms-5 display-4' src={loadingImage} alt="Cargando..."  width="120" height="120" />
          </Card>
        );
      }

  return (
   

    <h1>¡ENHORABUENA!</h1>

  
  )
}
