import React from 'react';
import { Carousel, Button, Container, Row, Col, Card } from 'react-bootstrap';

import UNO from '../../assets/11.png';
import DOS from '../../assets/22.png';
import TRES from '../../assets/33.png';
import CUATRO from '../../assets/44.png';
import CINCO from '../../assets/55.png';
import SEIS from '../../assets/66.png';


const reviews = [
        {
          id: 1,
          name: 'Laura García',
          comment: '"¡Estoy encantada con este servicio! Las fábulas personalizadas han sido una forma excepcional de enseñar valores a mis hijos. Cada historia es única y cautivadora. ¡Mis hijos las adoran!"',
          image: 'https://img.icons8.com/?size=160&id=7fgd1167iazH&format=png',
          imgPer: UNO,
          estreee: 'fas fa-star star',
        },
        {
          id: 2,
          name: 'Carlos Navarro',
          comment: '"Soy un padre ocupado, y esta app ha sido un salvavidas. Puedo crear fábulas personalizadas en minutos, y mis hijos las adoran. ¡La magia de la narrativa realmente brilla aquí!"',
          image: 'https://img.icons8.com/?size=160&id=7fgd1167iazH&format=png',
          imgPer: DOS,
          estreee: '',
        },
        {
                id: 3,
                name: 'María Sánchez',
                comment: '"Las fábulas personalizadas han traído tanta alegría a nuestras noches de lectura en familia. Mis hijos no pueden esperar para escuchar las aventuras de los personajes que ellos mismos protagonizan."',
                image: 'https://images.pexels.com/photos/4420634/pexels-photo-4420634.jpeg?auto=compress&cs=tinysrgb&w=800',
                imgPer: TRES,
                estreee: 'fas fa-star star',
              },
              {
                id: 4,
                name: 'Diego Pérez',
                comment: '"Una forma maravillosa de enseñar valores a los más jóvenes. Este servicio es innovador y valioso. Las historias son entretenidas y llenas de lecciones importantes. ¡Lo recomiendo altamente!"',
                image: 'https://img.icons8.com/?size=160&id=7fgd1167iazH&format=png',
                imgPer: CUATRO,
                estreee: 'fas fa-star star',
              },
              {
                id: 5,
                name: 'Ana López',
                comment: '"Me encanta cómo puedo personalizar las fábulas para que se adapten a los intereses de mis hijos. Ha hecho que la lectura sea aún más emocionante y significativa para ellos."',
                image: 'https://img.icons8.com/?size=160&id=7fgd1167iazH&format=png',
                imgPer: CINCO,
                estreee: '',
              },
              {
                id: 6,
                name: 'Valentina Rubio',
                comment: '"Como profesora, utilizo esta aplicación para inspirar a mis estudiantes a escribir sus propias historias. Es una herramienta educativa poderosa que estimula la creatividad y la empatía."',
                image: 'https://img.icons8.com/?size=160&id=7fgd1167iazH&format=png',
                imgPer: SEIS,
                estreee: 'fas fa-star star',
              },
        ];
      

export default function Reviews() {

  return (

<Container className="animawap">
  <Carousel className='fosifo' interval={null}>
    {reviews.map((review) => (
      <Carousel.Item key={review.id} className="text-">
        <Card className='bor bg-dark esca d-inline-block m-4 p-1'>
          <Card.Body className='d-flex p-4 white'>
            <div className="pfp"><img src={review.imgPer} alt=""/></div>
            <div className="comor ms-4">
              <h5 className="card-title">{review.name}</h5>
              <div className="rating">
                <i className="fas fa-star star"></i>
                <i className="fas fa-star star"></i>
                <i className="fas fa-star star"></i>
                <i className="fas fa-star star"></i>
                <i className={review.estreee}></i>
              </div>
              <p className="card-text">{review.comment}</p>
            </div>
          </Card.Body>
        </Card>
      </Carousel.Item>
    ))}
  </Carousel>
</Container>


  )
}
