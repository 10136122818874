import React from 'react';
import Reviews from './novisit/Reviews';
import { Link } from 'react-router-dom';
import logoImg from '../assets/logo1.png';
import { quiereComprarAppEvent } from './novisit/facebookPixelEvent';

export default function Precios2() {

    const onCliqueteApp = () => {
        quiereComprarAppEvent();
        console.log('clic en vamoallaApp')
      };


  return (
    <div>
        
        <div className=' container  dd mb-2'>
     
    <div className="d-flex align-items-center justify-content-center">
    <Link to="/"><img src={logoImg} alt='' width="150" height="150" className='mt-3'/></Link>
      </div>
          <h1 className='text-center mt-1 mb-4'>PAQUETES</h1>
         
          <div className="card mb-4 lool hong">
                    <div className="card-body">
                        <h5 className="card-title text-muted text-uppercase text-center">Padres Tradicionales®</h5>
                        <h6 className="card-price text-center">2,95 €</h6>
                        <hr/>
                        <ul className="fa-ul">
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span>Fomenta la imaginación de tu hijo con 3 cuentos únicos</li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span>Enseña valores esenciales a través de narrativas cautivadoras</li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span>Personaliza cada historia con el nombre de tu hijo y sus intereses</li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span><strong>Ampliar 1 cuento</strong></li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span><strong>Escuchar 1 cuento (Hasta Enero)</strong></li>

                        </ul>
                        <div className="d-flex justify-content-center">

                        <a
                    href="https://app.cuentameotro.com/sessiontop"
                    className="btn btn-block btn-success text-uppercase"
                    onClick={onCliqueteApp}
                  >
                    ¡Vamos allá!
                  </a>
                        </div>
                    </div>
                </div>


              
           
        
           <div className="card mb-4 tante hong">
                    <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase text-center">Padres Destacados®</h5>
                    <h6 className="card-price text-center">4,95 €</h6>
                        <hr/>
                        <ul className="fa-ul">
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span><strong>Duplica la diversión y el aprendizaje con 6 relatos personalizados</strong></li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span>Inspira a tu hijo a través de una variedad de historias emocionantes</li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span>Crea recuerdos especiales con narrativas adaptadas a sus gustos</li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span><strong>Ampliar 3 cuentos</strong></li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span><strong>Escuchar 2 cuentos</strong></li>
                        </ul>
                        <div className="d-flex justify-content-center">

                        <a
                    href="https://app.cuentameotro.com/sessiontop2"
                    className="btn btn-block btn-success text-uppercase"
                    onClick={onCliqueteApp}
                  >
                    ¡Vamos allá!
                  </a>
                        </div>
                    </div>
                </div>
      
              
        

        <div className="card lool hong">
                    <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase text-center">Super-Papás®</h5>
                    <h6 className="card-price text-center">7,95 €</h6>
                        <hr/>
                        <ul className="fa-ul">
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span>Una colección de 10 fábulas para un viaje inagotable de aprendizaje</li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span><strong>Desarrolla una biblioteca de valores y aventuras para tu hijo</strong></li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span>¡Ahorra más y disfruta de un conjunto de cuentos exclusivos!</li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span><strong>Ampliar 7 cuentos</strong></li>
                            <li className='mb-2'><span className="fa-li"><i className="fas fa-check"></i></span><strong>Escuchar 4 cuentos</strong></li>
                        </ul>
                        <div className="d-flex justify-content-center">
                        <a
                    href="https://app.cuentameotro.com/sessiontop3"
                    className="btn btn-block btn-success text-uppercase"
                    onClick={onCliqueteApp}
                  >
                    ¡Vamos allá!
                  </a>
                        </div>
                        </div>
                </div>

    </div>
    <Reviews></Reviews>
    <div className="d-flex aling-items-center justify-content-center">
    <Link to="/" className="btn btn-light w-75 mt-3 mb-5">
          INICIO
        </Link>
        </div>
    </div>
  )
}
