import React, { useState, useEffect } from 'react'
import { db } from '../../firebase'
import { useAuth } from '../../contexts/AuthContext.js'
import { Form, Button, Card, Alert } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import loadingImage from '../../assets/loco.svg';
import logoImg from '../../assets/logo1.png';

import { useLocation } from 'react-router-dom';

import { signUpSuccessAppEvent } from '../novisit/facebookPixelEvent';

export default function Despues() {

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()

    const { currentUser } = useAuth()
    const clienteUID = currentUser.uid
    const clienteRef = db.collection('customers').doc(clienteUID)
    const clienteRef2 = db.collection('Clientes').doc(clienteUID)
    const [creditoss, setCreditoss] = useState(0); 

    const location = useLocation();
    const nombre = new URLSearchParams(location.search).get('nombre');
    const apelli = new URLSearchParams(location.search).get('apellidos');

    const crearCreditos = async () => {
        const mail = currentUser.email;
        const Nombre = nombre;
        const Apellidos = apelli;
        try {
          await clienteRef2.set({
            nombre: Nombre,
            apellidos: Apellidos,
            email: mail,
            Creditos: 0,  
            CreditosA: 0,
            CreditosE: 0,
          });
          
          console.log('Campo "Creditos" creado en Firestore con valor 0');
          console.log('email', currentUser.email)
          setCreditoss(0)
        } catch (error) {
          console.error('Error al crear el campo "Creditos" en Firestore:', error);
        }
      };
    




      useEffect(() => {
        crearCreditos().then(() => {
          signUpSuccessAppEvent();
           navigate('/');
        });
      }, [navigate]);
    

      if (loading) {
        return (
            <Card className='d-flex align-items-center justify-content-center'>
               <img src={logoImg} alt='' width="130" height="130"/>
            <img className='ms-5 display-4' src={loadingImage} alt="Cargando..."  width="120" height="120" />
          
          </Card>
        );
      }

  return (


    <>

    <Card>
    <Card.Body>
        <div className="imo">
        <img src={logoImg} alt='' width="40" height="40"/>
        </div>
        <h2 className="text-center mb-4">CREANDO TU PERFIL</h2>
        {error && <Alert variant='danger'>{error}</Alert>}     
    </Card.Body>
  </Card>

    </>
  )
}
  

