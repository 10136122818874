import React, { useEffect, useState } from 'react';
import DocuPDF from './DocuPDF';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { Button } from 'react-bootstrap';

export default function VerPDF({ Respuesta1 }) {

    // const [verPdf, setVerPdf] = useState(false)

    // const Menu = () => (
    //     <div className="contecito">
    //     <p className='text-center'>{Respuesta1}</p>
        
    //     {/* <nav
    //       style={{
    //         display: "flex",
    //         borderBottom: "1px solid black",
    //         paddingBottom: "5px",
    //         justifyContent: "space-around",
    //       }}
    //     > */}
         
    //       {/* <Button
    //         variant="dark"
    //         onClick={() => {
    //           setVerPdf(!verPdf);

    //         }}
    //       >
    //         {verPdf ? "Ocultar PDF" : "Ver PDF"}
    //       </Button> */}
    //     <div className="d-flex justify-content-center align-items-center">
    //       <PDFDownloadLink
    //         document={<DocuPDF RespuestaX={Respuesta1}/>}
    //         fileName="MiCuento.pdf"
    //       >
    //         <Button variant="info">Descargar PDF</Button>
    //       </PDFDownloadLink>
    //       </div>
    //     {/* </nav> */}
    //     </div>
    //   );

      const handleRefreshClick = () => {
        window.location.reload();
      };

      return (
        <div className="">
        <div className="contecito">
          <p className='text-center'>{Respuesta1}</p>
    
          <div className="d-flex justify-content-center align-items-center">
            <PDFDownloadLink
              document={<DocuPDF RespuestaX={Respuesta1}/>}
              fileName="MiCuento.pdf"
            >
              <Button variant="info">Descargar PDF</Button>
            </PDFDownloadLink>
          </div>
    
          {/* {verPdf ? (
            <PDFViewer>
              <DocuPDF RespuestaX={Respuesta1}></DocuPDF> 
            </PDFViewer>
          ): null} */}
    
          {/* <p>{Respuesta1}</p> */}
    
          
        </div>
        <a className='btn-warning btn w-100 mt-3 mb-2' onClick={handleRefreshClick}>
        MIS CUENTOS
      </a>
     </div>
      );
    }
