import React, { useRef, useState } from 'react';
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from '../contexts/AuthContext.js';
import { Link, useNavigate } from 'react-router-dom';
import logoImg from '../assets/logo1.png';

export default function Login() {

    const emailRef = useRef()
    const passwordRef = useRef()
    const { login } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    async function handleSubmit(e) {
        e.preventDefault()

       

        try{
            setError('')
            setLoading(true)
       await login(emailRef.current.value, passwordRef.current.value)
       navigate("/")
        } catch {
            setError('Error al iniciar sesión')
        }

        setLoading(false)
    }

  return (
    <>

    <Card className='bor'>
    <Card.Body>
        <div className="imo">
        <img src={logoImg} alt='' width="125" height="125"/>
        </div>
        <h2 className="text-center mb-4">Inicio de sesión</h2>
        {error && <Alert variant='danger'>{error}</Alert>}
      <Form onSubmit={handleSubmit}>

     
        <Form.Group id="email">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" ref={emailRef} required />
        </Form.Group>
        <Form.Group id="password">
          <Form.Label>Contraseña</Form.Label>
          <Form.Control type="password" ref={passwordRef} required />
        </Form.Group>
        <Button disabled={loading} className="w-100 mt-2 btn-dark" type="submit">
              Iniciar sesión
            </Button>
      </Form>
      <div className="w100 text-center mt-4">
        <Link to="/forgot-password">¿Olvidaste la contraseña?</Link>
        </div>
    </Card.Body>
  </Card>

    <div className="w100 text-center mt-3">
        ¿Necesitas una cuenta? <Link to="/signup">Regístrate aquí</Link>
    </div>
    </>
  )
}
