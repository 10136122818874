import React, { useRef, useState } from 'react';
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from '../contexts/AuthContext.js';
import { Link, useNavigate } from 'react-router-dom';
import logoImg from '../assets/logo1.png';

export default function Signup() {

    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const nombreRef = useRef()
    const apeRef = useRef()
    const { signup } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

  
   

    async function handleSubmit(e) {
        e.preventDefault()

        const passworde = passwordRef.current.value;
        const nombreeee = nombreRef.current.value;
        const apell = apeRef.current.value;

        if (passworde.length < 6) {
          setError('La contraseña debe tener al menos 6 caracteres');
          return;
        }

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Las contraseñas no coinciden')
        } 

        if (!/[A-Z]/.test(passworde)) {
          setError('La contraseña debe contener al menos una letra mayúscula');
          return;
        }

        if (!/\d/.test(passworde)) {
          setError('La contraseña debe contener al menos un número');
          return;
        }

        try{
            setError('')
            setLoading(true)
       await signup(emailRef.current.value, passwordRef.current.value)
       console.log('nombreRefcURRENTvALUE:', nombreeee)
       console.log('apellRefcURRENTvALUE:', apell)
       navigate("/F3gH4I5J6K7lM8N9O0P1Q2rS3T4U5?nombre=" + nombreeee+ "&apellidos=" + apell);

        } catch {
            setError('Error al crear la cuenta')
        }

        setLoading(false)
    }

  return (
    <>

    <Card className='bor mtmedia'>
    <Card.Body>
        <div className="imo">
        <img src={logoImg} alt='' width="125" height="125"/>
        </div>
        <h2 className="text-center mb-4">Registro</h2>
        {error && <Alert variant='danger'>{error}</Alert>}
      <Form onSubmit={handleSubmit}>

        <div className='gol'> 
      <Form.Group  id="nombre">
          <Form.Label>Nombre</Form.Label>
          <Form.Control ref={nombreRef} type="text"  required />
        </Form.Group>
          
        <Form.Group id="apellidos">
          <Form.Label>Apellidos</Form.Label>
          <Form.Control ref={apeRef} type="text"  required />
        </Form.Group>
        </div>
         
        <Form.Group id="email">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" ref={emailRef} required />
        </Form.Group>
        <Form.Group id="password">
          <Form.Label>Contraseña</Form.Label>
          <Form.Control type="password" ref={passwordRef} required />
        </Form.Group>
        <Form.Group id="password-confirm">
          <Form.Label>Confirmación de contraseña</Form.Label>
          <Form.Control type="password" ref={passwordConfirmRef} required />
        </Form.Group>
        <Button disabled={loading} className="w-100 mt-2 btn-dark" type="submit">
              Registrar
            </Button>
      </Form>
    </Card.Body>
  </Card>

    <div className="w100 text-center mt-3 mbmedia">
        ¿Ya tienes una cuenta? <Link to="/login">Inicia sesión aquí</Link>
    </div>
    </>
  )
}
